import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CodeData } from '../utils/types';

const initialState: CodeData = {
    code: '',
    description: '',
    category: '',
    price: '',
    levels: [],
}

export const selectedCodeSlice = createSlice({
    name: 'selectedCodeState',
    initialState,
    reducers: {
        setSelectedCodeState(state, action: PayloadAction<CodeData>) {
            state.code = action.payload.code;
            state.description = action.payload.description;
            state.category = action.payload.category;
            state.price = action.payload.price;
            state.levels = action.payload.levels;
        },
        clearSelectedCodeState() {
            return initialState;
        },
    },
});

export const { clearSelectedCodeState, setSelectedCodeState } = selectedCodeSlice.actions;
export default selectedCodeSlice.reducer;
