import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BoxForm from '../../../components/global/BoxForm';
import InputField from '../../../components/form/InputField';
import { Link } from 'react-router-dom';
import ClassicButton from '../../../components/buttons/ClassicButton';
import regexValidation from '../../../utils/regex';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import loginBackground from '../../../assets/backgrounds/login.jpg';
import { useForgotPasswordMutation } from '../app/authService';
import { useState } from 'react';

export default function ForgotPassword() {
    const [
        sendRecoveryEmail,
        {

        },
    ] = useForgotPasswordMutation();

    const [messageForInvalidData, setMessageForInvalidData] = useState(false);
    const [sendedEmail, setSendedEmail] = useState<boolean>(false);

    const { t: form } = useTranslation('form');
    const { goToRoute } = useCustomNavigate();
    const {
        control,
        handleSubmit,
        /* reset,
        watch,
        getValues, */
        formState: { /*errors,*/ isValid, /*isDirty*/ },
    } = useForm<{
        email: string;
        isRemember: boolean;
    }>({
        mode: 'all',
        // defaultValues: {
        // 	email: 'example@local.xyz',
        // 	password: "!t'sAS3cretP@ssw0rd",
        // 	isRemember: false,
        // },
    });

    const onSubmit = async ({ ...args }) => {

        const { email } = args;
        const recipientEmail = {
            email: email
        }

        try {
            await sendRecoveryEmail(recipientEmail)
                .unwrap()
                .then((response) => {
                    if (response) {
                        setMessageForInvalidData(false);
                        setSendedEmail(true);
                    }
                })
        } catch (error) {
            console.error(error);
            setMessageForInvalidData(true);
        }
    };

    return (
        <>
            <div className="h-screen w-screen flex flex-col items-center justify-center relative" style={{
                backgroundImage: `url(${loginBackground})`,
                backgroundSize: '100% 100%',
                backgroundPosition: 'center',
            }}>
                <div className="overflow-hidden p-7">
                    {
                        !sendedEmail ?
                        <>
                            <BoxForm
                                classNameExtra={
                                    'flex flex-col items-center justify-center gap-4 w-[25rem] py-8 px-8 bg-white rounded-md'
                                }
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmit}
                                children={
                                    <>
                                        <h1 className="py-4 text-2xl font-bold text-primary">
                                            {'¿OLVIDÓ SU CONTRASEÑA?'}
                                        </h1>
                                        <p className="text-center font-semibold">Ingresa tu dirección de correo electrónico para que podamos
                                            enviarte un enlace de recuperación
                                        </p>
                                        <InputField
                                            classNameContainer="w-full"
                                            classExtraName="rounded-full"
                                            name={'email'}
                                            placeholder={'Correo electrónico'}
                                            control={control}
                                            type={'email'}
                                            rules={{
                                                pattern: {
                                                    value: regexValidation.email,
                                                    message: form('Correo inválido', {
                                                        input: form('email'),
                                                    }),
                                                },
                                                required: {
                                                    value: true,
                                                    message: form('Requerido', {
                                                        input: form('email'),
                                                    }),
                                                },
                                            }}
                                        />
                                        {messageForInvalidData &&
                                            <div className="text-red-600">Dirección de correo electrónico inválida</div>
                                        }
                                        <ClassicButton
                                            classNameContainer="w-full mb-2 rounded-full"
                                            isSubmit={true}
                                            label={'Enviar'}
                                            isDisabled={!isValid}
                                        />
                                        <p className="flex flex-row gap-2 font-semibold text-[#32475C99]">
                                            <Link className="text-primary" to={''} onClick={() => {
                                                goToRoute(`/login`);
                                            }}>
                                                Regresar
                                            </Link>
                                        </p>
                                    </>
                                }
                            />
                        </>
                        :
                        <>
                            <div className="flex flex-col items-center justify-center gap-4 w-[25rem] py-8 px-8 bg-white rounded-md">
                                <h1 className="mt-4 text-2xl font-bold text-primary text-center">
                                    {'EMAIL ENVIADO'}
                                </h1>
                                <p className="text-center font-semibold mb-4">
                                    Se ha enviado un correo electrónico a la dirección que proporcionaste. Encontrarás un botón para restablecer tu contraseña. 
                                </p>
                            </div>
                        </>
                    }
                </div>
                <div className="absolute bottom-10 text-white" >©2024 Intuit Inc. Todos los derechos reservados Ryder Health Plan, Invc. Preferencias de cookies, Privacidad y Condiciones </div>
            </div>
        </>
    );
}
