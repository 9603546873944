import { RouteObject } from 'react-router-dom';
import SearchService from '../pages/SearchService';
import { AddService } from '../pages/AddService';
import ProtectedRoute from '../../../components/auth/ProtectedRoute';


export const serviceMaintenanceRouter: RouteObject[] = [
    {
        path: 'services-maintenance',
        children: [
            {
                path: '',
                element: (
                    <ProtectedRoute>
                        <SearchService />
                    </ProtectedRoute> 
                ),
            },
            {
                path: 'add-service',
                element: (
                    <ProtectedRoute>
                        <AddService />
                    </ProtectedRoute>
                ),
            },
        ],
    },
];
