import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
//import Cookies from 'js-cookie';

const getAccessToken = () => sessionStorage.getItem('accessToken');

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_STAG_URL}`, // Define la URL base del backend
    prepareHeaders: (headers) => {
        //const token = Cookies.get('authToken');
        const token = getAccessToken();
        if (token) {
            //headers.set('authorization', `Bearer ${token}`);
            headers.set('x-access-token', token);
        }
        return headers;
    },
});

export const rtkApi = createApi({
    reducerPath: 'expressApi',
    baseQuery,
    endpoints: () => ({}),
});
