import { Dispatch, FormHTMLAttributes, SetStateAction } from 'react';
import ClassicButton from '../buttons/ClassicButton';

interface ModalInterface {
    ExtraClassNameForTitle?: FormHTMLAttributes<HTMLFormElement>['className'];
    ExtraClassName?: FormHTMLAttributes<HTMLFormElement>['className'];
    img?: string;
    setDispatch: Dispatch<SetStateAction<boolean>>;
    buttons?: ModalButtonInterface[];
    title: string;
    children?: React.ReactNode;
    onClose: () => void;
}

interface ModalButtonInterface {
    label: string;
    classNameContainer?: string;
    isSubmit: boolean;
    showButton: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function Modal({
    ExtraClassNameForTitle = '',
    ExtraClassName = '',
    setDispatch,
    img,
    buttons,
    children,
    title,
    onClose,
}: ModalInterface) {
    // Función para cerrar el modal, ejecutando la lógica pasada por onClose
    const closeModal = async (e: any) => {
        if (onClose.constructor.name === "AsyncFunction") { // Para saber si es una función asíncrona
            onClose();
            setDispatch(false);
        } else {
            e.preventDefault();
            e.stopPropagation();
            setDispatch((val) => !val);
        }
    };

    return (
        <div className="main-modal-container">
            <div
                className="main-modal-container-order"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setDispatch((val) => !val);
                }}>
                <div
                    className={`main-modal-box ${ExtraClassName} rounded-[1.75rem] relative`}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                    {img && <img className="w-28" src={img} alt="" />}
                    {children ? (
                        <>
                            {/*<p className="main-modal-title md:px-52 px-14 pt-4 text-center text-secondary text-3xl">{title}</p>*/}
                            <p className={`main-modal-title pt-4 text-center text-3xl ${ExtraClassNameForTitle}`}>{title}</p>
                            {children}
                        </>
                    ) : (
                            <p className={`main-modal-title pt-4 text-center text-3xl ${ExtraClassNameForTitle}`}>{title}</p>
                    )}
                    {/* <div className="text-2xl text-black absolute right-10 top-8 cursor-pointer"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setDispatch((val) => !val);
                        }}>X</div> */}
                    <button className="text-2xl text-black absolute right-10 top-8 cursor-pointer"
                        onClick={closeModal}>
                        X
                    </button>
                    <div className="main-modal-buttons mb-4">
                        {buttons?.map((item, index) => {
                            if (item.showButton) {
                                return (
                                    <ClassicButton
                                        key={index}
                                        isSubmit={item.isSubmit}
                                        classNameContainer={item.classNameContainer}
                                        label={item.label}
                                        onClick={item.onClick}
                                    />
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
