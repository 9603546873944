import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { selectedPlan } from '../utils/types';

const initialState: selectedPlan = {
    medicalPlanName: '',
}

export const selectedAdminPlanSlice = createSlice({
    name: 'selectedAminPlanState',
    initialState,
    reducers: {
        setSelectedAdminPlanState(state, action: PayloadAction<selectedPlan>) {
            state.medicalPlanName = action.payload.medicalPlanName;
        },
        clearSelectedAdminPlanState(state) {
            state.medicalPlanName = '';
        },
    },
});

export const { clearSelectedAdminPlanState, setSelectedAdminPlanState } = selectedAdminPlanSlice.actions;
export default selectedAdminPlanSlice.reducer;
