//import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function NotAuthorized({
    isHeightForced = false,
}: {
    isHeightForced?: boolean;
}) {

    const navigate = useNavigate();

    return (
        <div
            className={`flex flex-col items-center justify-center w-full ${isHeightForced ? "h-full" : "min-h-screen"
                } bg-gradient-to-r from-[#003545] via-[#146d76] to-[#23765d]`}>
            <h1 className="text-white text-center text-2xl md:text-3xl mb-6">
                No está autorizado para acceder a esta página
            </h1>
            <button
                onClick={() => {
                    navigate('/login');
                }}
                className="mt-2 bg-secondary text-white py-3 px-6 rounded-full hover:bg-[#3D8A32] transition-colors 
                    duration-300 text-2xl ">
                Ingresar
            </button>
        </div>
    );
}
