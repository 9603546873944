import { useEffect, useState } from "react"
import { MdOutlinePhoneInTalk } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import ryderLogo from '../../../assets/logos/ryder_health_logo.png';
import instagramLogo from '../../../assets/logos/instagram_logo.png';
import facebookLogo from '../../../assets/logos/facebook_logo.png';
import { useForm } from 'react-hook-form';
import InputFieldReactSelect from '../../../components/react-hook-form/inputs/InputFieldReactSelect';
import ClassicButton from "../../../components/buttons/ClassicButton";
import { useAppDispatch } from '../../../app/hookRTK';
import { setSearchDataState } from '../app/searchDataSlice';
import BoxForm from "../../../components/global/BoxForm";
import { AdminCodeAndPlanData, searchData } from "../utils/types";
import { useGetAdminCodesMutation } from "../app/adminCodeService";
import { useGetAllAdminCodesMutation, useGetAllCategoriesMutation } from "../app/adminCodeService";
import { setAdminCodeListState } from "../app/adminCodeListSlice";
import PlanMaintenance from "./PlanMaintenance";
import { AdminCodeData } from "../utils/types";
import { setSelectedAdminPlanState } from "../app/selectedAdminPlanSlice";
import { utils } from 'xlsx';
import Modal from "../../../components/global/Modal";

export default function SearchService() {

    const [
        adminCodeList,
        {

        },
    ] = useGetAdminCodesMutation();

    const [getAdminCodesAndPlan] = useGetAllAdminCodesMutation();
    const [getCodeCategories] = useGetAllCategoriesMutation();

    const [codeList, setCodeList] = useState<AdminCodeData[]>([]);
    const [firstSearch, setFirstSearch] = useState(true);
    const [searchFinished, setSearchFinished] = useState(false);
    const [adminCodesAndPlan, setAdminCodesAndPlan] = useState<AdminCodeAndPlanData[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [showConfirmModalForCsv, setShowConfirmModalForCsv] = useState<boolean>(true);

    const getAllCodes = async  () => {
        try {
            const allCodes = await getAdminCodesAndPlan('').unwrap();
            setAdminCodesAndPlan(allCodes.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getAllCategories = async () => {
        try {
            const categories: any = await getCodeCategories('').unwrap();
            setCategories(categories.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect( () => {
        getAllCategories();
        getAllCodes();
    }, []);
    
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const goHome = () => {
        navigate('/home');
    };

    const goAddService = () => {
        navigate('add-service');
    };

    const {
        control,
        handleSubmit,
        /* reset,
        watch, */
    } = useForm<searchData>({
        mode: 'all',
    });

    const [planOptions] = useState([
        { value: 'mplan_xl31we23gi9lnohvzusfyatw', label: 'Plan oro' },
        { value: 'mplan_wk20vd12fh8kmnguytrexzsv', label: 'Plan platino' },
    ]);

/*     const [categoryOptions] = useState([
        { value: 'Ambulatorio', label: 'Ambulatorio' },
        { value: 'Hospitalización', label: 'Hospitalización' },
        { value: 'Emergencia', label: 'Emergencia' },
        { value: 'Examenes', label: 'Examenes' },
        { value: 'Radiology (XRAY)', label: 'Radiology (XRAY)' },
        { value: 'Radiology (CT SCAN)', label: 'Radiology (CT SCAN)' },
        { value: 'Radiology (MRI)', label: 'Radiology (MRI)' },
        { value: 'Laboratory', label: 'Laboratory' },
        { value: 'Ambulatory', label: 'Ambulatory' },
        { value: 'Nuclear Medicine', label: 'Nuclear Medicine' },
        { value: 'Ambulatory Procedures (Orthopedics)', label: 'Ambulatory Procedures (Orthopedics)' },
        { value: 'Ambulatory Procedures (Gastroenterology)', label: 'Ambulatory Procedures (Gastroenterology)' },
    ]); */

    const onSubmit = async ( { ...args }) => {
        setSearchFinished(false);
        setFirstSearch(false);
        const {
            medical_plan_id,
            category,
        } = args as searchData;

        let medicalPlanValue: string | undefined;
        let categoryValue: string | undefined;
        let medicalPlanName: string = '';

        if (medical_plan_id && typeof medical_plan_id === 'object' && 'value' in medical_plan_id) {
            medicalPlanValue = medical_plan_id.value;
            medicalPlanName = medical_plan_id.label;
            console.log('El resultado: ', medicalPlanName)
        }

        if (category && typeof category === 'object' && 'value' in category) {
            categoryValue = category.value;
        }

        const objectToSend = {
            medical_plan_id: medicalPlanValue ? [medicalPlanValue] : [],
            category: categoryValue || '',
        };

        dispatch(setSearchDataState(objectToSend));
        dispatch(setSelectedAdminPlanState({ medicalPlanName: medicalPlanName }));

        try {
            const response = await adminCodeList(objectToSend).unwrap();

            // Para asegurarse de que `response` esté definido y tenga el formato esperado
            if (response && response.data.length > 0) {
                setCodeList(response.data);
                dispatch(setAdminCodeListState(response.data)); // Para pasar el array de `AdminCodeData[]` al dispatch
            }

        } catch (error) {
            setCodeList([]);
            console.error(error);
        }

        setSearchFinished(true);
    };

    const downloadCSV = (data: any) => {
        // Crear una hoja de cálculo en formato CSV
        const formattedData = data.map((item: any) => ({
            plan: item.plan_name,
            codigo: item.code,
            descripcion: item.description,
            tarifa: item.price,
            nivel1Copago: item.levels[0]?.copago || '', // copago de nivel 1
            nivel1Coasegurado: item.levels[0]?.deductible || '', // coasegurado de nivel 1
            nivel2Copago: item.levels[1]?.copago || '', // copago de nivel 2
            nivel2Coasegurado: item.levels[1]?.deductible || '' // coasegurado de nivel 2
        }));

        const worksheet = utils.json_to_sheet(formattedData);

        // Crear un libro de trabajo
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Tabla');
        const csvOutput = utils.sheet_to_csv(worksheet);

        // Crear un blob y descargarlo
        const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'todos_los_codigos.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        setShowConfirmModalForCsv(false);
    };

    const modalBodyForCsv = () => {
        return (
            <div className="text-xl md:px-40 text-center font-medium mb-4">Puedes encontrar el archivo en la carpeta que seleccionaste al momento de la descarga.</div>
        )
    }

    const categoryOptions = categories.map((category) => ({
        label: category,
        value: category,
    }));

    return (
        <>
            {!showConfirmModalForCsv && (
                <Modal
                    ExtraClassNameForTitle="text-black"
                    ExtraClassName="w-[45rem]"
                    title={`Haz descargado con éxito los códigos en csv`}
                    setDispatch={setShowConfirmModalForCsv}
                    children={modalBodyForCsv()}
                    onClose={() => {
                        setShowConfirmModalForCsv(true);
                    }}
                    buttons={[
                        {
                            classNameContainer: 'w-fit sm:w-60 rounded-[0.5rem] text-white text-xl font-bold bg-secondary hover:bg-[#3D8A32]',
                            isSubmit: false,
                            label: ('Volver'),
                            showButton: (() => {
                                return true
                            })(),
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowConfirmModalForCsv(true);
                            },
                        },
                    ]}
                />
            )}
            <div className="">
                {/*<div className="bg-primary py-4 text-white text-lg mb-4">
                    <div className="mx-auto flex max-w-6xl items-center justify-between">
                        <div>
                            <span className="mr-2">Suscríbete a Plan Ryder Hoy</span>
                            <span className="ml-2">Sobre Nosotros</span>
                        </div>
                        <div>
                            <span className="mr-2">
                                <MdOutlinePhoneInTalk className="inline w-5 h-5" />
                                (787)988-7910
                            </span>
                            <span>|</span>
                            <span className="ml-2">(787)852-0846</span>
                        </div>
                    </div>
                </div>
                <div className="mx-auto flex max-w-6xl items-center justify-between">
                    <div>
                        <img className="w-32 h-24 cursor-pointer" src={ryderLogo} alt="Ryder Logo" onClick={goHome} />
                    </div>
                    <div className="flex items-center justify-end flex-2">
                        <a href="https://www.facebook.com/PlanRyder/" target="_blank">
                            <img className="w-9 h-9" src={facebookLogo} alt="Facebook Logo" />
                        </a>
                        <a href="https://www.instagram.com/PlanRyder/" target="_blank">
                            <img className="w-9 h-9" src={instagramLogo} alt="Instagram Logo" />
                        </a>
                    </div>
                </div>
                <hr className="bg-secondary mt-6 " style={{ height: '3.5px' }} />*/}
                <div className="bg-[#eff0ee] min-h-screen">
                    <div className="mx-auto grid max-w-6xl md:grid-cols-3 py-14">
                        <div className="col-span-3 text-center">
                            <h2 className="text-3xl font-bold text-primary">
                                Mantenimiento de servicios
                            </h2>
                        </div>
                    </div>
                    <div className="mx-auto flex max-w-6xl flex-col items-center space-y-4 md:flex-row md:justify-between md:space-y-0">
                        <div>
                            <button className="btn btn-wide text-base text-white bg-primary hover:bg-[#015A94]"
                                onClick={goAddService}>
                                Agregar código
                            </button>
                        </div>
                        { 
                            adminCodesAndPlan?.length == 0 ?
                            <div>
                                
                            </div>
                            :
                            <div>
                                <button className="btn text-base text-white bg-[#F8A22C] w-auto hover:bg-[#D78324]"
                                    onClick={() => downloadCSV(adminCodesAndPlan)}>
                                    Descargar todos los códigos
                                </button>
                            </div>
                        }
                    </div>

                    <div className="bg-white mx-auto mb-6 max-w-6xl mt-6 pb-16 rounded-[2.5rem] shadow-md border-[2px] border-[#e4e4e4]">
                        <div className="mx-auto grid md:grid-cols-3 py-6 mt-6">
                            <div className="col-span-3 text-center">
                                <h3 className="text-xl font-medium text-gray mb-5 ">
                                    Busca el servicio que quieres editar o borrar con su código o descripción o categoría.
                                </h3>

                            </div>
                        </div>
                        <BoxForm
                            classNameExtra="flex mx-auto max-w-6xl items-center justify-items-center justify-evenly px-14"
						    handleSubmit={handleSubmit}
                            onSubmit={onSubmit}
                            children={
                                <>
                                    <div className="flex items-center mr-2 font-bold text-lg">Plan médico:</div>
                                    <div className="grow">
                                        <InputFieldReactSelect
                                            classNameContainer={''}
                                            name={'medical_plan_id'}
                                            control={control}
                                            placeholder={'Plan médico'}
                                            options={planOptions}
                                        />
                                    </div>
                                    <div className="flex items-center ml-4 mr-2 font-bold text-lg">Categoría:</div>
                                    {
                                        categories?.length == 0 ?
                                        <div className="grow">
                                            {<div className="pl-2">Cargando...</div>}
                                        </div>
                                        :
                                        <div className="grow">
                                            <InputFieldReactSelect
                                                classNameContainer={''}
                                                name={'category'}
                                                control={control}
                                                placeholder={'Categoría'}
                                                options={categoryOptions}
                                            />
                                        </div>
                                    }
                                    <ClassicButton
                                        classNameContainer="rounded-lg bg-secondary hover:bg-[#3D8A32] text-base w-36 mx-4"
                                        isSubmit={true}
                                        label={'Filtrar'}
                                    />
                                </>
                            }
                        />
                    </div>
                    {/* {!searchFinished && !firstSearch ?  //!searchFinished && !firstSearch  ?
                        <div className="mx-auto grid md:grid-cols-3 py-6 mt-6">
                            <div className="col-span-3 text-center">
                                <h4 className="text-xl font-medium text-gray mb-5 ">
                                    Cargando, por favor espere...
                                </h4>
                            </div>
                        </div>
                        : codeList?.length > 0  ?
                            <PlanMaintenance
                                codeList={(codeList)} setCodeList={(setCodeList)}
                            />
                        
                        : firstSearch
                            ?
                            <div className="mx-auto grid md:grid-cols-3 py-6 mt-6">
                                <div className="col-span-3 text-center">
                                    <h4 className="text-xl font-medium text-gray mb-5 ">
                                        Seleccione un plan médico y una categoría.
                                    </h4>
                                </div>
                            </div>
                            :
                            <div className="mx-auto grid md:grid-cols-3 py-6 mt-6">
                                <div className="col-span-3 text-center">
                                    <h4 className="text-xl font-medium text-gray mb-5 ">
                                        Código(s) o servicio(s) no encontrado(s).
                                    </h4>
                                </div>
                            </div>
                    } */}
                    { firstSearch ?
                        <div className="mx-auto grid md:grid-cols-3 py-6 mt-6">
                            <div className="col-span-3 text-center">
                                <h4 className="text-xl font-medium text-gray mb-5 ">
                                    Seleccione un plan médico y una categoría.
                                </h4>
                            </div>
                        </div>
                        : !searchFinished  ?
                            <div className="mx-auto grid md:grid-cols-3 py-6 mt-6">
                                <div className="col-span-3 text-center">
                                    <h4 className="text-xl font-medium text-gray mb-5 ">
                                        Cargando, por favor espere...
                                    </h4>
                                </div>
                            </div>
                        : codeList?.length > 0 ?
                            <PlanMaintenance
                                codeList={(codeList)} setCodeList={(setCodeList)}
                            />
                        :
                        <div className="mx-auto grid md:grid-cols-3 py-6 mt-6">
                            <div className="col-span-3 text-center">
                                <h4 className="text-xl font-medium text-gray mb-5 ">
                                    Código(s) o servicio(s) no encontrado(s).
                                </h4>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}
