import { useState, useEffect } from 'react';

/**
 * The function calculates the range of page numbers based on the total number of data items and the
 * desired number of rows per page.
 * @param {any[]} data - The `data` parameter is an array of any type of data. It represents the data
 * that needs to be paginated.
 * @param {number} rowsPerPage - The `rowsPerPage` parameter is the number of rows that you want to
 * display per page. It determines how many pages will be needed to display all the data.
 * @returns The function `calculateRange` returns an array of numbers representing the range of pages
 * for pagination.
 */
const calculateRange = (data: unknown[], rowsPerPage: number) => {
    const range: unknown[] = [];
    const num = Math.ceil(data.length / rowsPerPage);
    for (let i = 1; i <= num; i++) {
        range.push(i);
    }
    return range;
};

/**
 * The function "sliceData" takes an array of data, a page number, and a number of rows per page, and
 * returns a sliced portion of the data based on the given page and rows per page.
 * @param {any} data - The `data` parameter is an array of any type. It represents the data that needs
 * to be sliced.
 * @param {number} page - The `page` parameter represents the current page number that you want to
 * display.
 * @param {number} rowsPerPage - The `rowsPerPage` parameter represents the number of rows or items
 * that should be displayed per page in a paginated data set.
 * @returns The function `sliceData` returns a portion of the `data` array based on the `page` and
 * `rowsPerPage` parameters.
 */
const sliceData = (data: unknown[], page: number, rowsPerPage: number) => {
    return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
};

/**
 * The useTable function is a custom hook in TypeScript that takes an array of data, page number, and
 * rows per page as inputs, and returns a sliced portion of the data based on the current page and rows
 * per page, as well as the range of pages available.
 * @param {any[]} data - An array of data that represents the table rows.
 * @param {number} page - The `page` parameter represents the current page number of the table. It is
 * used to determine which portion of the data should be displayed in the table.
 * @param {number} rowsPerPage - The `rowsPerPage` parameter specifies the number of rows to be
 * displayed per page in the table.
 * @returns The function `useTable` returns an object with two properties: `slice` and `range`.
 */
const useTable = (data: unknown[], page: number, rowsPerPage: number) => {
    const [tableRange, setTableRange] = useState<number[] | unknown[]>([]);
    const [slice, setSlice] = useState<unknown[]>([]);

    useEffect(() => {
        if (page > 0) {
            const range = calculateRange(data, rowsPerPage);
            setTableRange([...range]);

            const slice = sliceData(data, page, rowsPerPage);
            setSlice([...slice]);
        }
    }, [data, setTableRange, page, setSlice, rowsPerPage]);

    return { slice, range: tableRange };
};

export default useTable;
