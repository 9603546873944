// navigateUtils.js
import {
	NavigateFunction,
	NavigateOptions,
	To,
	useNavigate,
} from 'react-router-dom';

export const useCustomNavigate = () => {
	const navigate: NavigateFunction = useNavigate();

	const goToRoute = (to: To, options?: NavigateOptions | undefined) => {
		navigate(to, options);
	};

	const goToReturn = () => {
		navigate(-1);
	};

	return {
		goToRoute,
		goToReturn,
	};
};
