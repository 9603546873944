import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BoxForm from '../../../components/global/BoxForm';
import InputField from '../../../components/form/InputField';
import { Link } from 'react-router-dom';
import ClassicButton from '../../../components/buttons/ClassicButton';
//import { useAppDispatch } from '../../../app/hookRTK';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import loginBackground from '../../../assets/backgrounds/login.jpg';
import { useResetPasswordMutation, useGetTokenMutation } from '../app/authService';
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
//import { jwtDecode } from 'jwt-decode';

export default function ResetPassword() {
    const [
        resetPassword,
        {

        },
    ] = useResetPasswordMutation();

    const [
        userToken,
        {

        },
    ] = useGetTokenMutation();

    const params = useParams();
    const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState(false);
    const [messageForInvalidData, setMessageForInvalidData] = useState(false);
    const [changedPassword, setChangedPassword] = useState<boolean>(false);
    const [validToken, setValidToken] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    //const navigate = useNavigate();
    const token: string = (params && 'token' in params && params.token) || '';

    const { t: form } = useTranslation('form');
    //const [showConfirmModal, setShowConfirmModal] = useState<boolean>(true);
    //const [disabledForm, setDisabledForm] = useState<boolean>(true);
    const { goToRoute } = useCustomNavigate();
    //const dispatch = useAppDispatch();
    const {
        control,
        handleSubmit,
        /* reset,
        watch,
        getValues, */
        formState: { /*errors,*/ isValid, /*isDirty*/ },
    } = useForm<{
        email: string;
        password: string;
        isRemember: boolean;
    }>({
        mode: 'all',
        // defaultValues: {
        // 	email: 'example@local.xyz',
        // 	password: "!t'sAS3cretP@ssw0rd",
        // 	isRemember: false,
        // },
    });

    const validateToken = async () => {
        try {
            await userToken(token)
                .unwrap()
                .then((response) => {
                    if (response) {
                        //console.log('El token del usuario: ', response.data.token);
                        const decoded = jwtDecode(response.data.token);
                        //console.log('El decoded: ', decoded);
                        const currentTime = Math.floor(Date.now() / 1000); // Obtener el tiempo actual en segundos
                        if (typeof decoded.exp != 'undefined') {
                            if (decoded.exp < currentTime) {
                                //console.log('Ese token es inválido')
                                setValidToken(false);
                            } else {
                                //console.log('Ese token es válido')
                                setValidToken(true);
                            }
                        }
                    }
                })
        } catch (error: any) {
            console.error(error);
            setValidToken(false);
        }

        /* try {
            const decoded = jwtDecode('$2a$08$h0kV9Dj7QfxgPjpSbgVVUujkdGjSxooYOm8IQkPaxsagOsLQkINta');
            console.log('El decoded: ', decoded);
            const currentTime = Math.floor(Date.now() / 1000); // Obtener el tiempo actual en segundos
            console.log(Date.now());
            if (typeof decoded.exp != 'undefined') {
                if (decoded.exp < currentTime) {
                    console.log('Ese token es no válido')
                    setValidToken(false);
                } else {
                    console.log('Ese token es válido')
                    setValidToken(true);
                }
            }
            setValidToken(true);
        } catch (error) {
            setValidToken(false);
        } */
        setLoading(false);
    }

    useEffect(() => {
        validateToken();
    }, []);

    const onSubmit = async ({ ...args }) => {

        const { password, confirmPassword } = args;
        const recoverPassword = {
            newPassword: password,
            hash: token
        }

        if (password === confirmPassword) {
            try {
                await resetPassword(recoverPassword)
                    .unwrap()
                    .then((response) => {
                        if (response) {
                            setMessageForInvalidData(false);
                            setPasswordsDoNotMatch(false);
                            //const decoded = jwtDecode(response.data.accessToken);
                            setChangedPassword(true);
                        }
                    })
                } catch (error: any) {
                    console.error(error);
                    if (error?.data?.message === "Password cannot be the same as the previous one!") {
                        setMessageForInvalidData(true);
                        setPasswordsDoNotMatch(false);
                    }
                }
        } else {
            setMessageForInvalidData(false);
            setPasswordsDoNotMatch(true);
        }
    };

    return (
        <>
            <div className="h-screen w-screen flex flex-col items-center justify-center relative" style={{
                backgroundImage: `url(${loginBackground})`,
                backgroundSize: '100% 100%',
                backgroundPosition: 'center',
            }}>
                <div className="overflow-hidden p-7">
                    {
                        loading ?
                            <>
                                <div className="flex flex-col items-center justify-center gap-4 w-[25rem] py-8 px-8 bg-white rounded-md">
                                    <h1 className="mt-4 text-2xl font-bold text-primary text-center">
                                        {'VERIFICANDO'}
                                    </h1>
                                    <p className="text-center font-semibold">
                                        Cargando...
                                    </p>
                                    <p className="flex flex-row gap-2 font-semibold text-[#32475C99]">
                                        Por favor espere.
                                    </p>
                                </div>
                            </>
                        :
                        !validToken ?
                            <>
                                <div className="flex flex-col items-center justify-center gap-4 w-[25rem] py-8 px-8 bg-white rounded-md">
                                    <h1 className="mt-4 text-2xl font-bold text-primary text-center">
                                        {'OPERACIÓN NO PERMITIDA'}
                                    </h1>
                                    <p className="text-center font-semibold">
                                        Su enlace de recuperación de contraseña ha expirado o usted ya actualizó
                                        su contraseña. Si no recuerda su contraseña, deberá hace clic en el 
                                        siguiente enlace:
                                    </p>
                                    <p className="flex flex-row gap-2 font-semibold text-[#32475C99]">
                                        <Link className="text-primary" to={''} onClick={() => {
                                            goToRoute(`/forgot-password`);
                                        }}>
                                            ¿Olvidó su contraseña?
                                        </Link>
                                    </p>
                                </div>
                            </>
                        :
                        !changedPassword ?
                        <>
                            <BoxForm
                                classNameExtra={
                                    'flex flex-col items-center justify-center gap-4 w-[25rem] py-8 px-8 bg-white rounded-md'
                                }
                                handleSubmit={handleSubmit}
                                onSubmit={onSubmit}
                                children={
                                    <>
                                        <h1 className="py-4 text-2xl font-bold text-primary">
                                            {'RECUPERAR CONTRASEÑA'}
                                        </h1>
                                        <InputField
                                            classNameContainer="w-full"
                                            classExtraName="rounded-full"
                                            name={'password'}
                                            placeholder={'Contraseña'}
                                            control={control}
                                            type={'password'}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: form('Requerido', {
                                                        input: form('password'),
                                                    }),
                                                },
                                            }}
                                        />
                                        <InputField
                                            classNameContainer="w-full"
                                            classExtraName="rounded-full"
                                            name={'confirmPassword'}
                                            placeholder={'Confirmar ontraseña'}
                                            control={control}
                                            type={'password'}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: form('Requerido', {
                                                        input: form('confirmPassword'),
                                                    }),
                                                },
                                            }}
                                        />
                                        {messageForInvalidData &&
                                            <div className="text-center text-red-600">La contraseña no puede ser igual que la anterior</div>
                                        }
                                        {passwordsDoNotMatch &&
                                            <div className="text-center text-red-600">Las contraseñas no coinciden</div>
                                        }
                                        <ClassicButton
                                            classNameContainer="w-full mb-2 rounded-full"
                                            isSubmit={true}
                                            label={'Resetear Contraseña'}
                                            isDisabled={!isValid}
                                        />
                                        <p className="flex flex-row gap-2 font-semibold text-[#32475C99]">
                                            <Link className="text-primary" to={''} onClick={() => {
                                                goToRoute(`/login`);
                                            }}>
                                                Iniciar Sesión
                                            </Link>
                                        </p>
                                    </>
                                }
                            />
                        </>
                        :
                        <>
                            <div className="flex flex-col items-center justify-center gap-4 w-[25rem] py-8 px-8 bg-white rounded-md"> 
                                    <h1 className="mt-4 text-2xl font-bold text-primary text-center">
                                        {'CONTRASEÑA ACTUALIZADA'}
                                    </h1>
                                    <p className="text-center font-semibold">
                                        Felicidades, su contraseña se ha actualizado.
                                    </p>
                                    <p className="flex flex-row gap-2 font-semibold text-[#32475C99]">
                                        <Link className="text-primary" to={''} onClick={() => {
                                            goToRoute(`/login`);
                                        }}>
                                            Iniciar Sesión
                                        </Link>
                                    </p>
                            </div>
                        </>
                    }
                </div>
                <div className="absolute bottom-10 text-white" >©2024 Intuit Inc. Todos los derechos reservados Ryder Health Plan, Invc. Preferencias de cookies, Privacidad y Condiciones </div>
            </div>
        </>
    );
}
