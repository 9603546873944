import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CodeData } from '../utils/types';


export const codeListSlice = createSlice({
    name: 'codeListState',
    initialState: [] as CodeData[],
    reducers: {
        setCodeListState(_, action: PayloadAction<CodeData[]>) {
            return action.payload
        },
        clearCodeListState() {
            return [];
        },
    },
});

export const { clearCodeListState, setCodeListState } = codeListSlice.actions;
export default codeListSlice.reducer;
