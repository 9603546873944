import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
//import { RootState } from './storeRTK';

const baseQuery = fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_NEST_API_DEV_URL}`, // Define la URL base del backend
});

export const nestApi = createApi({
    reducerPath: 'nestApi',
    baseQuery,
    endpoints: () => ({}),
});
