import { rtkApi } from '../../../app/rtkApi';
import {
    GetAdminCodesSuccessResponse,
    UpdateAdminCodesSuccessResponse,
    searchData,
    codesToUpdate,
    CodesToCreate,
    CreateAdminCodesSuccessResponse,
    codesToDelete,
    DeleteAdminCodesSuccessResponse,
    GetAllAdminCodesSuccessResponse,
    GetCodeCategoriesSuccessResponse
} from '../utils/types';

export const adminCodeService = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getAdminCodes: builder.mutation<GetAdminCodesSuccessResponse, searchData>({
            query: (data) => ({
                url: '/codes/admin',
                method: 'POST',
                body: data,
            }),
        }),
        updateAdminCodes: builder.mutation<UpdateAdminCodesSuccessResponse, codesToUpdate>({
            query: (data) => ({
                url: `/codes`,
                method: 'PUT',
                body: data,
            }),
        }),
        createAdminCodes: builder.mutation<CreateAdminCodesSuccessResponse, CodesToCreate>({
            query: (data) => ({
                url: `/codes`,
                method: 'POST',
                body: data,
            }),
        }),
        deleteAdminCodes: builder.mutation<DeleteAdminCodesSuccessResponse, codesToDelete>({
            query: (data) => ({
                url: `/codes`,
                method: 'DELETE',
                body: data,
            }),
        }),
        getAllAdminCodes: builder.mutation<GetAllAdminCodesSuccessResponse, any>({
            query: () => ({
                url: '/codes',
                method: 'GET',
            }),
        }),
        getAllCategories: builder.mutation<GetCodeCategoriesSuccessResponse, any>({
            query: () => ({
                url: '/categories',
                method: 'GET',
            }),
        }),
    }),

    overrideExisting: false,
});

export const {
    useGetAdminCodesMutation,
    useUpdateAdminCodesMutation,
    useCreateAdminCodesMutation,
    useDeleteAdminCodesMutation,
    useGetAllAdminCodesMutation,
    useGetAllCategoriesMutation,
    //useAdminCodesQuery,
} = adminCodeService;
