//uso de propagacion por problema de Readonly de ReactSelect en inmutabilidad del estado de redux

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlanData } from '../utils/types';

const initialState: Partial<PlanData> = {
  category: null,
  code: null,
  description: null,
  fee: null,
  goldPlanLevelOneCoinsured: null,
  goldPlanLevelOneCopayment: null,
  goldPlanLevelTwoCoinsured: null,
  goldPlanLevelTwoCopayment: null,
  platinumPlanLevelOneCoinsured: null,
  platinumPlanLevelOneCopayment: null,
  platinumPlanLevelTwoCoinsured: null,
  platinumPlanLevelTwoCopayment: null,
  otherCategory: null,
}

export const planDataSlice = createSlice({
    name: 'planData',
    initialState,
    reducers: {
      setPlanData(state, action: PayloadAction<PlanData>) {
        state.category = action.payload.category;
        state.otherCategory = action.payload.otherCategory;
        state.code = action.payload.code;
        state.description = action.payload.description;
        state.fee = action.payload.fee;
        state.goldPlanLevelOneCoinsured = action.payload.goldPlanLevelOneCoinsured;
        state.goldPlanLevelOneCopayment = action.payload.goldPlanLevelOneCopayment;
        state.goldPlanLevelTwoCoinsured = action.payload.goldPlanLevelTwoCoinsured;
        state.goldPlanLevelTwoCopayment = action.payload.goldPlanLevelTwoCopayment;
        state.platinumPlanLevelOneCoinsured = action.payload.platinumPlanLevelOneCoinsured;
        state.platinumPlanLevelOneCopayment = action.payload.platinumPlanLevelOneCopayment;
        state.platinumPlanLevelTwoCoinsured = action.payload.platinumPlanLevelTwoCoinsured;
        state.platinumPlanLevelTwoCopayment = action.payload.platinumPlanLevelTwoCopayment;
      },
      clearPlanData(state) {
        state.category = null
        state.otherCategory = null
        state.code = null
        state.description = null
        state.fee = null
        state.goldPlanLevelOneCoinsured = null
        state.goldPlanLevelOneCopayment = null
        state.goldPlanLevelTwoCoinsured = null
        state.goldPlanLevelTwoCopayment = null
        state.platinumPlanLevelOneCoinsured = null
        state.platinumPlanLevelOneCopayment = null
        state.platinumPlanLevelTwoCoinsured = null
        state.platinumPlanLevelTwoCopayment = null
      },
    },
});

export const { setPlanData, clearPlanData } = planDataSlice.actions;
export default planDataSlice.reducer;
