export default function formatCurrencyToDecimal(value: string | number): string|number {
	// Convertir el valor a número, si es necesario
	const numericValue =
		typeof value === 'string'
			? parseFloat(value.replace(/[^0-9.-]+/g, ''))
			: value;

	// Si el valor no es un número válido, retornar 0
	if (isNaN(numericValue)) {
		return `${0.00}`;
	}

	// Asegurarse de que siempre tenga dos decimales
	return `$${numericValue.toFixed(2)}`;
}

export const formatCurrency = (value: string) => {
    if (!value) return "";

    const numericValue = parseFloat(value) / 100; // Convierte en valor de dólares
    return numericValue.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };