import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminCodeData } from '../utils/types';


export const adminCodeListSlice = createSlice({
    name: 'adminCodeListState',
    initialState: [] as AdminCodeData[],
    reducers: {
        setAdminCodeListState(_, action: PayloadAction<AdminCodeData[]>) {
            return action.payload
        },
        clearAdminCodeListState() {
            return [];
        },
    },
});

export const { clearAdminCodeListState, setAdminCodeListState } = adminCodeListSlice.actions;
export default adminCodeListSlice.reducer;
