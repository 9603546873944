import { ReactNode } from "react";
import NotAuthorized from "./NotAuthorized";

interface ProtectedRouteProps {
    children: ReactNode;
    isHeightForced?: boolean;
}

export default function ProtectedRoute({
    children,
}: ProtectedRouteProps) {
    const accessToken = sessionStorage.getItem("accessToken");
    if (!accessToken) {
        return <NotAuthorized />;
    }

    return <>{children}</>;
}
