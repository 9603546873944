/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTMLAttributes } from 'react';
import { Controller, Control, RegisterOptions } from 'react-hook-form';
import Select, { GroupBase, OptionsOrGroups, StylesConfig } from 'react-select';

interface InputFieldProps {
    classNameContainer?: HTMLAttributes<HTMLDivElement>['className'];
    name: string;
    control: Control<any, unknown>;
    rules?: RegisterOptions;
    placeholder?: string;
    options?: OptionsOrGroups<any, GroupBase<unknown>>;
    indexValue?: number;
    isClearable?: boolean;
    isSearchable?: boolean;
    showLabel?: boolean;
}

const styleConfigDefault: StylesConfig = {
    control: (provided) => ({
        ...provided,
        border: '3px solid #c3c3c3', // Cambia el borde
        borderRadius: '9999px', // Cambia el borde redondeado
        boxShadow: 'none', // Agrega un borde azul cuando está enfocado
        '&:hover': {
            borderColor: '#a0aec0', // Cambia el color del borde al pasar el ratón sobre él
        },
        backgroundColor: 'white', // Cambia el color de fondo del elemento seleccionado
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#5f62e6' : 'transparent', // Cambia el color de fondo del elemento seleccionado
        color: state.isSelected ? 'white' : '#4a5568', // Cambia el color del texto del elemento seleccionado
        '&:hover': {
            backgroundColor: '#5f62e6', // Cambia el color de fondo al pasar el ratón sobre el elemento
            color: 'white', // Cambia el color del texto al pasar el ratón sobre el elemento
        },
    }),
};

export default function InputFieldReactSelect({
    classNameContainer,
    name,
    control,
    rules,
    placeholder = '',
    options = [],
    indexValue,
    isClearable = true,
    isSearchable = true,
    showLabel = true,
}: InputFieldProps): JSX.Element {
    return (
        <div className={`input-container ${classNameContainer}`}>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field, fieldState: { error } }) => (
                    <>
                        <Select
                            {...field}
                            id={name}
                            placeholder={placeholder}
                            options={options}
                            defaultValue={indexValue ? options[indexValue] : null}
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            menuPosition={'fixed'}
                            className={'h-10'}
                            styles={styleConfigDefault}
                            isDisabled={rules?.disabled}
                        />
                        {!showLabel && (
                            <label htmlFor={name} className="label-text">
                                {placeholder}
                            </label>
                        )}
                        {error && (
                            <span className="mt-2 text-sm text-red-600">
                                {error?.message}
                            </span>
                        )}
                    </>
                )}
            />
        </div>
    );
}
