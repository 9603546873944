import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/index.css';
import './i18n.ts';
import { routerArray } from './router/index';
import { persistor, store } from './app/storeRTK';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

const router = createBrowserRouter(routerArray);

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate
				i18nIsDynamicList={true}
				loading={<>...</>}
				persistor={persistor}>
				<Suspense fallback={<div>Loading...</div>}>
					<RouterProvider router={router} />
				</Suspense>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
);
