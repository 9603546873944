import { ButtonHTMLAttributes } from 'react';

interface IClassicButton extends React.HTMLAttributes<HTMLButtonElement> {
    label: string;
    classNameContainer?: ButtonHTMLAttributes<HTMLButtonElement>['className'];
    isSubmit: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    form?: string;
    isDisabled?: boolean;
}

export default function ClassicButton({
    label = '',
    classNameContainer,
    isSubmit = false,
    onClick = () => { },
    isDisabled = false,
    form,
}: IClassicButton) {
    return (
        <button
            disabled={isDisabled}
            form={form}
            className={`${classNameContainer}  border-[#d2d7db] disabled:bg-[#32475C61] disabled:text-white ${isSubmit ? 'border-primary bg-primary text-white' : 'border'
                } px-6 py-2 placeholder:text-[#32475C61] focus:outline-none focus:ring-0`}
            onClick={onClick}>
            {label}
        </button>
    );
}
