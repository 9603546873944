import {
    configureStore,
    ThunkAction,
    Action,
    combineReducers,
} from '@reduxjs/toolkit';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    persistStore,
    Persistor,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rtkApi } from './rtkApi';
import { nestApi } from './nestApi';
import searchDataSlice from '../modules/serviceMaintenance/app/searchDataSlice';
import planDataSlice from '../modules/serviceMaintenance/app/addServiceSlice';
import selectedPlanSlice from '../modules/plan/app/selectedPlanSlice';
import codeListSlice from '../modules/plan/app/codeListSlice';
import selectedCodeSlice from '../modules/plan/app/selectedCodeSlice';
import adminCodeListSlice from '../modules/serviceMaintenance/app/adminCodeListSlice';
import selectedAdminPlanSlice from '../modules/serviceMaintenance/app/selectedAdminPlanSlice';

const rootReducer = combineReducers({
    //planSlice,
    searchDataSlice,
    planDataSlice,
    selectedPlanSlice,
    selectedAdminPlanSlice,
    codeListSlice,
    selectedCodeSlice,
    adminCodeListSlice,
    [rtkApi.reducerPath]: rtkApi.reducer,
    [nestApi.reducerPath]: nestApi.reducer,
});

export const persistedReducer = persistReducer(
    {
        key: 'root',
        version: 1,
        storage,
    },
    rootReducer,
);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(rtkApi.middleware, nestApi.middleware),
});

export const persistor: Persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
