//import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BoxForm from '../../../components/global/BoxForm';
import InputField from '../../../components/form/InputField';
import { Link } from 'react-router-dom';
import ClassicButton from '../../../components/buttons/ClassicButton';
import regexValidation from '../../../utils/regex';
//import { useAppDispatch } from '../../../app/hookRTK';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import loginBackground from '../../../assets/backgrounds/login.jpg';
import { useGetUserDataMutation } from '../app/authService';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
//import { jwtDecode } from 'jwt-decode';

export default function Login() {
    const [
        userData,
        {

        },
    ] = useGetUserDataMutation();

    const [messageForInvalidData, setMessageForInvalidData] = useState(false);
    const navigate = useNavigate();

    const { t: form } = useTranslation('form');
    //const [showConfirmModal, setShowConfirmModal] = useState<boolean>(true);
    //const [disabledForm, setDisabledForm] = useState<boolean>(true);
    const { goToRoute } = useCustomNavigate();
    //const dispatch = useAppDispatch();
    const {
        control,
        handleSubmit,
        /* reset,
        watch,
        getValues, */
        formState: { /*errors,*/ isValid, /*isDirty*/ },
    } = useForm<{
        email: string;
        password: string;
        isRemember: boolean;
    }>({
        mode: 'all',
        // defaultValues: {
        // 	email: 'example@local.xyz',
        // 	password: "!t'sAS3cretP@ssw0rd",
        // 	isRemember: false,
        // },
    });

    const onSubmit = async ({ ...args }) => {
       /*  const { email, password } = args;
        const credentials = {
            email: email,
            password: password
        }

        console.log({ email, password });

        try {
            const response: any = await userData(credentials).unwrap();

            if (response) {
                console.log('Me pude loggear: ', response)
                setMessageForInvalidData(false);
                navigate("/services-maintenance");
            }
        } catch (error) {
            console.error(error);
            console.log('No me pude loggear: ')
            setMessageForInvalidData(true);
        } */

        const { email, password } = args;
        const credentials = {
            email: email,
            password: password
        }

        try {
            await userData(credentials)
            .unwrap()
            .then((response) => {
                if (response) {
                    setMessageForInvalidData(false);
                    //const decoded = jwtDecode(response.data.accessToken);
                    sessionStorage.setItem("accessToken", response.data.accessToken);
                    navigate("/services-maintenance");
                }
            })
        } catch (error) {
            console.error(error);
            setMessageForInvalidData(true);
        }

    };

    return (
        <>
            <div className="h-screen w-screen flex flex-col items-center justify-center relative" style={{
                backgroundImage: `url(${loginBackground})`,
                backgroundSize: '100% 100%',
                backgroundPosition: 'center',
                }}>
                <div className="overflow-hidden p-7">
                    <BoxForm
                        classNameExtra={
                            'flex flex-col items-center justify-center gap-4 w-[25rem] py-8 px-8 bg-white rounded-md'
                        }
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        children={
                            <>
                                <h1 className="py-4 text-2xl font-bold text-primary">
                                    {'INICIAR SESIÓN'}
                                </h1>
                                <InputField
                                    classNameContainer="w-full"
                                    classExtraName="rounded-full"
                                    name={'email'}
                                    placeholder={'Correo electrónico'}
                                    control={control}
                                    type={'email'}
                                    rules={{
                                        pattern: {
                                            value: regexValidation.email,
                                            message: form('Correo inválido', {
                                                input: form('email'),
                                            }),
                                        },
                                        required: {
                                            value: true,
                                            message: form('Requerido', {
                                                input: form('email'),
                                            }),
                                        },
                                    }}
                                />
                                <InputField
                                    classNameContainer="w-full"
                                    classExtraName="rounded-full"
                                    name={'password'}
                                    placeholder={'Contraseña'}
                                    control={control}
                                    type={'password'}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: form('Requerido', {
                                                input: form('password'),
                                            }),
                                        },
                                    }}
                                />
                                { messageForInvalidData &&
                                    <div className="text-red-600">Los datos ingresados son incorrectos</div>
                                }
                                <ClassicButton
                                    classNameContainer="w-full mb-2 rounded-full"
                                    isSubmit={true}
                                    label={'Ingresar'}
                                    isDisabled={!isValid}
                                />
                                <p className="flex flex-row gap-2 font-semibold text-[#32475C99]">
                                    <Link className="text-primary" to={''} onClick={() => {
                                        goToRoute(`/forgot-password`);
                                    }}>
                                        ¿Olvidaste tu contraseña?
                                    </Link>
                                </p>
                            </>
                        }
                    />
                    
                </div>
                <div className="absolute bottom-10 text-white" >©2024 Intuit Inc. Todos los derechos reservados Ryder Health Plan, Invc. Preferencias de cookies, Privacidad y Condiciones </div>
            </div>
        </>
    );
}
