// type RegexType = {
//   [key: string]: RegExp;
// };

type RegexType = {
    email: RegExp;
    phone: RegExp;
    noNumber: RegExp;
    allNumber: RegExp;
    concurrency: RegExp;
    onlyLetters: RegExp;
    password: RegExp;
};

const regexValidation: RegexType = {
    email: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9._.-]+\.[A-Za-z]{2,}$/i,
    phone:
        // /(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})/s,
        /^(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})$/,
    noNumber: /^[a-zA-ZÁáäÉéÍíÓóÚúÜüÑñ\s]+$/i,
    // allNumber: /^\d+$/,
    // concurrency: /^\$\d*\.?\d*$/,
    concurrency: /^\$?\d{1,3}(,\d{3})*(\.\d{2})?$/,
    // concurrency: /^\$?\d*\.?\d*$/m,
    // concurrency: /^\$?\d{1,3}(,\d{3})*(\.\d*)?$/,
    // concurrency: /^\$?\d{1,3}(,\d{3})*(\.\d+)?$/,
    // concurrency: /^\$?\d{1,3}(,?\d{3})*(\.\d{2})?$/,
    allNumber: /^\d*\.?\d+$/,
    onlyLetters: /^[a-zA-Z\s'-]+$/,
    password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
};

export default regexValidation;
