import esUser from '../modules/user/locales/es';

const es = {
	...esUser,
	html: {
		lang: 'es',
		title: 'Bienvenido',
		description: 'Adios',
	},
	common: {
		title: 'Bienvenido a Stack',
		environment: 'Entorno: ',
	},
};

export default es;
