//uso de propagacion por problema de Readonly de ReactSelect en inmutabilidad del estado de redux

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { searchData } from '../utils/types';

const initialState: searchData = {
    medical_plan_id: null,
    category: null,
}

export const searchDataSlice = createSlice({
    name: 'searchDataState',
    initialState,
    reducers: {
        setSearchDataState(state, action: PayloadAction<searchData>) {
            state.medical_plan_id = action.payload.medical_plan_id;
            state.category = action.payload.category;
        },
        clearSearchDataState(state) {
            state.medical_plan_id = null;
            state.category = null;
        },
    },
});

export const { clearSearchDataState, setSearchDataState } = searchDataSlice.actions;
export default searchDataSlice.reducer;
