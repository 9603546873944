import { useEffect, useState } from "react"
import { useForm } from 'react-hook-form';
import ClassicButton from '../../../components/buttons/ClassicButton';
import useTable from "../../../hooks/useTable";
import TableFooter from "../../../components/table/TableFooter";
import Modal from "../../../components/global/Modal";
import { useUpdateAdminCodesMutation, useDeleteAdminCodesMutation, useGetAdminCodesMutation } from "../app/adminCodeService";
import { useAppSelector } from '../../../app/hookRTK';
import { utils } from 'xlsx';

export default function PlanMaintenance({ codeList, setCodeList }: any) {
    const searchData = useAppSelector((state) => state.searchDataSlice);
    const selectedAdminPlanName = useAppSelector((state) => state.selectedAdminPlanSlice);
    const [ getAdminCodes ] = useGetAdminCodesMutation();
    const [newData, setNewData] = useState<any[]>([]);

    const [rowPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const { slice, range } = useTable(newData, page, rowPerPage) as {
        slice: any[];
        range: number[];
    };

    const [editableRow, setEditableRow] = useState<string | null>(null); // Estado para saber qué fila está en edición
    
    const [updateAdminCode] = useUpdateAdminCodesMutation();
    const [deleteAdminCode] = useDeleteAdminCodesMutation();
    const [rowToUpdate, setRowToUpdate] = useState<string>();
    const [rowToDelete, setRowToDelete] = useState<string>('');
    const [searchInput, setSearchInput] = useState<string>(""); // Estado para el input de búsqueda
    const [searchedValue, setSearchedValue] = useState<string>(""); // Se usará para almacenar el texto completo introducido en el input
                                                                    //de búsqueda antes de hacer clic en el botón Buscar
    const [filteredData, setFilteredData] = useState<any[]>([]); // Estado para los datos filtrados


    const findCodes = async () => {
        try {
            const response = await getAdminCodes(searchData).unwrap();
            if (response && response.data.length > 0) {
                setCodeList(response.data)
            }
        } catch (error) {
            console.error("Error al buscar los datos:", error);
        }
    }

    const handleEditClick = (rowId: string) => {
        setEditableRow(rowId); // Cambiar la fila a modo edición
    };

    const handleSaveClick = async (rowId: string | undefined) => {
        const updatedRow = newData.find((row) => row.code === rowId);
        const dataToSend = {
            codes: [
                {
                    code_id: updatedRow.levels[0].code_id,
                    copago: updatedRow.levels[0].copago,
                    deductible: updatedRow.levels[0].deductible

                },
                {
                    code_id: updatedRow.levels[1].code_id,
                    copago: updatedRow.levels[1].copago,
                    deductible: updatedRow.levels[1].deductible

                }
            ]
        }

        try {
            await updateAdminCode(dataToSend); // Llamada a la mutación
            setEditableRow(null); // Para salir del modo edición
            setShowConfirmModalForUpdate(true);
        } catch (error) {
            console.error("Error al actualizar los datos:", error);
        }
    };

    const handleDeleteClick = async (code: string) => {
        const codeToSend = {
            code_id: code,
        }

        try {
            await deleteAdminCode(codeToSend); // Llamada a la mutación
            await findCodes();
            setShowConfirmModalForDelete(true);
        } catch (error) {
            console.error("Error al actualizar los datos:", error);
        }
    };

    const handleSearch = () => {
        const filtered = newData.filter(
            (row) =>
                row.code.toLowerCase().includes(searchInput.toLowerCase()) ||
                row.description.toLowerCase().includes(searchInput.toLowerCase()) ||
                row.price.toString().includes(searchInput)
        );
        setFilteredData(filtered);
        setSearchedValue(searchInput);
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        rowId: string,
        field: string
    ) => {
        setNewData((prevData) =>
            prevData.map((row) =>
                row.code === rowId
                    ? {
                        ...row,
                        levels: row.levels.map((level: any, index: any) =>
                            field === "levelOneCopayment" && index === 0
                                ? { ...level, copago: e.target.value }
                                : field === "levelOneCoinsured" && index === 0
                                    ? { ...level, deductible: e.target.value }
                                    : field === "levelTwoCopayment" && index === 1
                                        ? { ...level, copago: e.target.value }
                                        : field === "levelTwoCoinsured" && index === 1
                                            ? { ...level, deductible: e.target.value }
                                            : level
                        ),
                    }
                    : row
            )
        );
    };

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
    };

    const {
        //control,
    } = useForm<{
    }>({
        mode: 'all',
    });

    const [showConfirmModalForDelete, setShowConfirmModalForDelete] = useState<boolean>(true);
    const [showConfirmModalForUpdate, setShowConfirmModalForUpdate] = useState<boolean>(true);
    const [showConfirmModalForCsv, setShowConfirmModalForCsv] = useState<boolean>(true);

    useEffect(() => {
        codeList.length > 0 ? setPage(1) : setPage(0);
        if (searchedValue?.length > 0) {
            setNewData(filteredData);
        } else {
            setNewData(codeList);
        }
     }, [codeList, searchedValue]);

    const onClick = (data: unknown) => {
        const option = data as { value: string | unknown; label: string };
        if (typeof option === 'object') {
            if (typeof option.value === 'string') {
                setRowsPerPage(Number(option?.value));
            }
        }
    };

    const modalBodyForDelete = () => {
        return (
            <div className="text-xl md:px-40 text-center font-medium mb-4">Ten en cuenta que el código se eliminará de todos los planes y niveles.</div>
        )
    }

    const modalBodyForUpdate = () => {
        return (
            <div className="text-xl md:px-40 text-center font-medium mb-4">¿Está seguro de que desea actualizar el código seleccionado?</div>
        )
    }

    const openConfirModalForUpdate = (rowId: string | undefined) => {
        setShowConfirmModalForUpdate(false);
        setRowToUpdate(rowId);
    }

    const openConfirModalForDelete = (row: string) => {
        setShowConfirmModalForDelete(false);
        setRowToDelete(row);
    }

    const downloadCSV = (data: any) => {
        // Crear una hoja de cálculo en formato CSV

        const formattedData = data.map((item: any) => ({
            plan: selectedAdminPlanName.medicalPlanName,
            codigo: item.code,
            descripcion: item.description,
            tarifa: item.price,
            nivel1Copago: item.levels[0]?.copago || '', // copago de nivel 1
            nivel1Coasegurado: item.levels[0]?.deductible || '', // coasegurado de nivel 1
            nivel2Copago: item.levels[1]?.copago || '', // copago de nivel 2
            nivel2Coasegurado: item.levels[1]?.deductible || '' // coasegurado de nivel 2
        }));

        const worksheet = utils.json_to_sheet(formattedData);

        // Crear un libro de trabajo
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Tabla');

        // Generar el archivo CSV
        //const csvOutput = utils.write(workbook, { bookType: 'csv', type: 'string' });
        const csvOutput = utils.sheet_to_csv(worksheet);

        // Crear un blob y descargarlo
        const blob = new Blob([csvOutput], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'tabla.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        setShowConfirmModalForCsv(false);
    };

    const modalBodyForCsv = () => {
        return (
            <div className="text-xl md:px-40 text-center font-medium mb-4">Puedes encontrar el archivo en la carpeta que seleccionaste al momento de la descarga.</div>
        )
    }

    return (
        <>
            {!showConfirmModalForDelete && (
                <Modal
                    ExtraClassNameForTitle="text-black"
                    ExtraClassName="w-[45rem]"
                    title={`¿Quieres eliminar el código ${rowToDelete}?`}
                    setDispatch={setShowConfirmModalForDelete}
                    children={modalBodyForDelete()}
                    onClose={async () => {
                        // Lo siguiente se ejecuta al cerrar el modal
                        await findCodes()
                        setShowConfirmModalForDelete(true);
                        if (editableRow != null) {
                            setEditableRow(null);
                        }
                    }}
                    buttons={[
                        /* {
                            classNameContainer: 'w-fit sm:w-60 rounded-[0.5rem] text-white text-xl font-bold bg-[#e5585b] hover:bg-[#5C5F61]',
                            isSubmit: false,
                            label: ('Volver'),
                            showButton: (() => {
                                return false
                            })(),
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowConfirmModal(true);
                            },
                        }, */
                        {
                            classNameContainer: 'sm:w-60 rounded-[0.5rem] text-white text-xl font-bold bg-[#54843a] hover:bg-[#476e31]',
                            isSubmit: false,
                            label: ('Sí'),
                            showButton: (() => {
                                return true;
                            })(),
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleDeleteClick(rowToDelete);
                            },
                        },
                        {
                            classNameContainer: 'sm:w-60 rounded-[0.5rem] text-white text-xl font-bold !bg-[#e55d5f] hover:!bg-[#e1393b]',
                            isSubmit: true,
                            label: ('No'),
                            showButton: (() => {
                                return true;
                            })(),
                            onClick: async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                await findCodes()
                                setShowConfirmModalForDelete(true);
                                if (editableRow != null) {
                                    setEditableRow(null);
                                } 
                            },
                        },
                    ]}
                />
            )}
            {!showConfirmModalForUpdate && (
                <Modal
                    ExtraClassNameForTitle="text-black"
                    ExtraClassName="w-[45rem]"
                    title={'Aviso'}
                    setDispatch={setShowConfirmModalForUpdate}
                    children={modalBodyForUpdate()}
                    onClose={async () => {
                        // Lo siguiente se ejecuta al cerrar el modal
                        await findCodes();
                        setShowConfirmModalForUpdate(true);
                        setEditableRow(null);
                    }}
                    buttons={[
                        /* {
                            classNameContainer: 'w-fit sm:w-60 rounded-[0.5rem] text-white text-xl font-bold bg-[#e5585b] hover:bg-[#5C5F61]',
                            isSubmit: false,
                            label: ('Volver'),
                            showButton: (() => {
                                return false
                            })(),
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowConfirmModalForUpdate(true);
                            },
                        }, */
                        {
                            classNameContainer: 'sm:w-60 rounded-[0.5rem] text-white text-xl font-bold bg-[#54843a] hover:bg-[#476e31]',
                            isSubmit: false,
                            label: ('Sí'),
                            showButton: (() => {
                                return true;
                            })(),
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleSaveClick(rowToUpdate);
                            },
                        },
                        {
                            classNameContainer: 'sm:w-60 rounded-[0.5rem] text-white text-xl font-bold !bg-[#e55d5f] hover:!bg-[#e1393b]',
                            isSubmit: true,
                            label: ('No'),
                            showButton: (() => {
                                return true;
                            })(),
                            onClick: async (e) =>  {
                                e.preventDefault();
                                e.stopPropagation();
                                //await findCodes(searchData);
                                await findCodes();
                                setShowConfirmModalForUpdate(true);
                                setEditableRow(null);
                            },
                        },
                    ]}
                />
            )}

            {!showConfirmModalForCsv && (
                <Modal
                    ExtraClassNameForTitle="text-black"
                    ExtraClassName="w-[45rem]"
                    title={`Haz descargado con éxito la tabla en csv`}
                    setDispatch={setShowConfirmModalForCsv}
                    children={modalBodyForCsv()}
                    onClose={() => {
                        setShowConfirmModalForCsv(true);
                    }}
                    buttons={[
                       {
                            classNameContainer: 'w-fit sm:w-60 rounded-[0.5rem] text-white text-xl font-bold bg-secondary hover:bg-[#3D8A32]',
                            isSubmit: false,
                            label: ('Volver'),
                            showButton: (() => {
                                return true
                            })(),
                            onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowConfirmModalForCsv(true);
                            },
                        },
                    ]}
                />
            )}
            {slice?.length > 0 ?
                <div>
                    <div className="mx-auto mb-6 max-w-6xl">
                        <hr className="bg-[#c3c3c3] h-1" />
                    </div>
                    <div className="flex mx-auto max-w-6xl justify-between">
                        <div className="flex flex-col ">
                            <div className="flex justify-stretch">
                                <input
                                    className="flex justify-stretch input input-bordered rounded-full
                                        h-10 w-full focus:placeholder-transparent border-[3px] border-[#c3c3c3]"
                                    name="search"
                                    type="text"
                                    onChange={handleSearchInputChange}
                                />
                                <ClassicButton
                                    classNameContainer="rounded-lg bg-secondary hover:bg-[#3D8A32] text-base w-36 mx-4"
                                    isSubmit={true}
                                    label={'Buscar'}
                                    onClick={handleSearch}
                                />
                            </div>
                            <div className="p-1 font-semibold">*Código o descripción.</div>
                        </div>
                        <div className="flex items-center mr-2 font-bold text-lg"><span className="mr-4">Descargar Tabla (csv)</span>
                            <button className="p-2 rounded-full bg-[#8c8e91] transition-colors"
                                onClick={() => downloadCSV(filteredData.length > 0 ? filteredData : slice)}>
                                <img
                                    className="w-[30px] rounded-full"
                                    src={'/serviceMaintenance/download.svg'}
                                    alt={'download'}
                                />
                            </button>
                        </div>
                    </div>
                    <div
                        className={
                            'bg-[#d8dbd8] py-6 gap-5'
                        }>
                        <TableFooter
                            range={range}
                            slice={slice}
                            setPage={setPage}
                            page={page}
                            rowPerPage={Number(rowPerPage)}
                            onClick={onClick}
                        />
                    </div>
                    <div className="mx-auto max-w-7xl py-8">
                        <h2 className="text-center font-bold text-xl mb-4">{ selectedAdminPlanName.medicalPlanName }</h2>
                        <div className="overflow-x-auto max-w-full w-full">
                            <table className="w-full">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Descripción</th>
                                        <th>Tarifa</th>
                                        <th><div>Nivel 1</div><div>copago</div></th>
                                        <th>Nivel 1 coasegurado</th>
                                        <th><div>Nivel 2</div><div>copago</div></th>
                                        <th>Nivel 2 coasegurado</th>
                                        <th className="text-[#eff0ee] opacity-0">Editar</th>
                                        <th className="text-[#eff0ee] opacity-0">Eliminar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {slice.length > 0 ? (
                                        slice.map((data: any) => (
                                            <tr key={data.code}>
                                                <td className="p-0">
                                                    <input
                                                        className="rounded-s-full"
                                                        name="code"
                                                        value={data.code}
                                                        type="text"
                                                        placeholder=""
                                                        onChange={() => {}}
                                                        disabled
                                                    />
                                                </td>
                                                <td className="p-0">
                                                    <input
                                                        className=""
                                                        name="description"
                                                        value={data.description}
                                                        type="text"
                                                        placeholder=""
                                                        onChange={() => { }}
                                                        disabled
                                                    />
                                                </td>
                                                <td className="p-0">
                                                    <input
                                                        className=""
                                                        name="fee"
                                                        type="text"
                                                        value={data.price}
                                                        placeholder=""
                                                        onChange={() => { }}
                                                        disabled
                                                    />
                                                </td>
                                                <td className="p-0 max-w-32">
                                                    <input
                                                        className=""
                                                        name="levelOneCopayment"
                                                        min="0"
                                                        type="number"
                                                        value={data.levels[0].copago}
                                                        onChange={(e) => handleInputChange(e, data.code, 'levelOneCopayment')}
                                                        disabled={editableRow !== data.code} // Solo editable si está en edición
                                                    />
                                                </td>
                                                    <td className="p-0 max-w-32">
                                                    <input
                                                        className=""
                                                        name="levelOneCoinsured"
                                                        min="0"
                                                        type="number"
                                                        value={data.levels[0].deductible}
                                                        onChange={(e) => handleInputChange(e, data.code, 'levelOneCoinsured')}
                                                        disabled={editableRow !== data.code}
                                                    />
                                                </td>
                                                <td className="p-0 max-w-32">
                                                    <input
                                                        className=""
                                                        name="levelTwoCopayment"
                                                        min="0"
                                                        type="number"
                                                        value={data.levels[1].copago}
                                                        onChange={(e) => handleInputChange(e, data.code, 'levelTwoCopayment')}
                                                        disabled={editableRow !== data.code}
                                                    />
                                                </td>
                                                <td className="p-0">
                                                    <input
                                                        className="rounded-e-full max-w-32"
                                                        name="levelTwoCoinsured"
                                                        min="0"
                                                        type="number"
                                                        value={data.levels[1].deductible}
                                                        onChange={(e) => handleInputChange(e, data.code, 'levelTwoCoinsured')}
                                                        disabled={editableRow !== data.code}
                                                    />
                                                </td>
                                                <td>
                                                    {editableRow === data.code ? (
                                                        <div className="pb-1 cursor-pointer group" onClick={() => openConfirModalForUpdate(data.code)}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                fill="#8c8e91"
                                                                height="1.75em"
                                                                width="1.75em"
                                                                className="group-hover:fill-[#000]"
                                                            >
                                                                <path fill="none" d="M0 0h24v24H0z" />
                                                                <path d="M4 3h14l2.707 2.707a1 1 0 01.293.707V20a1 1 0 01-1 1H4a1 1 0 01-1-1V4a1 1 0 011-1zm3 1v5h9V4H7zm-1 8v7h12v-7H6zm7-7h2v3h-2V5z" />
                                                            </svg>
                                                        </div>
                                                    ) : (
                                                        <div className="pb-1 cursor-pointer group" onClick={() => handleEditClick(data.code)}>
                                                            <svg
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                                stroke="#8c8e91"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                height="1.5em"
                                                                width="1.5em"
                                                                className="group-hover:fill-[#49A93E]"
                                                            >
                                                                <path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
                                                            </svg>
                                                        </div> 
                                                    )}
                                                </td>
                                                {/* <td className="">
                                                    <div className="ml-4 pb-1 cursor-pointer">
                                                        <img
                                                            className="h-full"
                                                            src={'/serviceMaintenance/edit.svg'}
                                                            alt={'editar'}
                                                        />
                                                    </div>
                                                </td> */}
                                                <td className="" onClick={() => openConfirModalForDelete(data.code)}>
                                                    <div className="ml-4 pb-1 cursor-pointer group">
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height="1.5em"
                                                            width="1.5em"
                                                            className="fill-current text-[#8c8e91] group-hover:fill-[#e32f34]"
                                                        >
                                                            <path fill="none" d="M0 0h24v24H0z" />
                                                            <path d="M7 4V2h10v2h5v2h-2v15a1 1 0 01-1 1H5a1 1 0 01-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={9} className="text-center p-4">Cargando, por favor espere</td>
                                            </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            : <div className="mx-auto grid md:grid-cols-3 py-6 mt-6">
                <div className="col-span-3 text-center">
                    <h4 className="text-xl font-medium text-gray mb-5 ">
                        Código(s) o servicio(s) no encontrado(s).
                    </h4>
                </div>
            </div>
            }
        </>
    )
}
