import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { lazy } from 'react';
import Login from '../modules/user/pages/Login';
import { serviceMaintenanceRouter } from '../modules/serviceMaintenance/router/serviceMaintenanceRouter';
import ForgotPassword from '../modules/user/pages/ForgotPassword';
import ResetPassword from '../modules/user/pages/ResetPassword';

const Core = lazy(() => import('../components/layout/Core'));
const Layout = lazy(() => import('../components/global/Layout'));

export const routerArray: RouteObject[] = [
	{ index: true, element: <Navigate to={'/login'} replace /> },
	{
		path: '',
		element: (
			<Core />
		),
		children: [
			{
						path: 'login',
						element: <Login />,
					},
					{
						path: 'forgot-password',
						element: <ForgotPassword />,
					},
					{
						path: 'reset-password/:token',
						element: <ResetPassword />,
					},
					{
						element: <Layout />,
						children: [
							...serviceMaintenanceRouter,
						]
					}

		],
	},
];
