import { Dispatch, FormHTMLAttributes, SetStateAction } from 'react';
import ClassicButton from '../buttons/ClassicButton';

interface ModalInterface {
    ExtraClassNameForTitle?: FormHTMLAttributes<HTMLFormElement>['className'];
    ExtraClassName?: FormHTMLAttributes<HTMLFormElement>['className'];
    img?: string;
    setDispatch: Dispatch<SetStateAction<boolean>>;
    buttons?: ModalButtonInterface[];
    title: string;
    children?: React.ReactNode;
}

interface ModalButtonInterface {
    label: string;
    classNameContainer?: string;
    isSubmit: boolean;
    showButton: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function ModalWithoutX({
    ExtraClassNameForTitle = '',
    ExtraClassName = '',
    img,
    buttons,
    children,
    title,
}: ModalInterface) {
    return (
        <div className="main-modal-container">
            <div
                className="main-modal-container-order">
                <div
                    className={`main-modal-box ${ExtraClassName} rounded-[1.75rem] relative`}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                    {img && <img className="w-28" src={img} alt="" />}
                    {children ? (
                        <>
                            {/*<p className="main-modal-title md:px-52 px-14 pt-4 text-center text-secondary text-3xl">{title}</p>*/}
                            <p className={`main-modal-title pt-4 text-center text-3xl ${ExtraClassNameForTitle}`}>{title}</p>
                            {children}
                        </>
                    ) : (
                        <p className={`main-modal-title pt-4 text-center text-3xl ${ExtraClassNameForTitle}`}>{title}</p>
                    )}
                    <div className="main-modal-buttons mb-4">
                        {buttons?.map((item, index) => {
                            if (item.showButton) {
                                return (
                                    <ClassicButton
                                        key={index}
                                        isSubmit={item.isSubmit}
                                        classNameContainer={item.classNameContainer}
                                        label={item.label}
                                        onClick={item.onClick}
                                    />
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
