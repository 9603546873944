import { rtkApi } from '../../../app/rtkApi';
import {
    GetUserDataSuccessResponse,
    Credentials,
    ForgotPasswordSuccessResponse,
    RecipientEmail,
    RecoveryPassword,
    UpdatePasswordSuccessResponse,
    GetTokenSuccessResponse
} from '../utils/types';

export const authService = rtkApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserData: builder.mutation<GetUserDataSuccessResponse, Credentials>({
            query: (data) => ({
                url: '/auth/login',
                method: 'POST',
                body: data,
            }),
        }),
        forgotPassword: builder.mutation<ForgotPasswordSuccessResponse, RecipientEmail>({
            query: (data) => ({
                url: `/auth/forgot-password`,
                method: 'POST',
                body: data,
            }),
        }),
        resetPassword: builder.mutation<UpdatePasswordSuccessResponse, RecoveryPassword>({
            query: ({ ...recoveryData }) => ({
                url: `/auth/reset-password`,
                method: 'PUT',
                body: recoveryData,
            }),
        }),
        getToken: builder.mutation<GetTokenSuccessResponse, string>({
            query: (id) => ({
                url: `/auth/user/${id}`,
                method: 'GET',
            }),
        }),

    }),
    overrideExisting: false,
});

export const {
    useGetUserDataMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useGetTokenMutation,
} = authService;
