import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { selectedPlan } from '../utils/types';

const initialState: selectedPlan = {
    medicalPlanName: '',
}

export const selectedPlanSlice = createSlice({
    name: 'selectedPlanState',
    initialState,
    reducers: {
        setSelectedPlanState(state, action: PayloadAction<selectedPlan>) {
            state.medicalPlanName = action.payload.medicalPlanName;
        },
        clearSelectedPlanState(state) {
            state.medicalPlanName = '';
        },
    },
});

export const { clearSelectedPlanState, setSelectedPlanState } = selectedPlanSlice.actions;
export default selectedPlanSlice.reducer;
