import React, { useEffect } from 'react';
//import { useTranslation } from 'react-i18next';
import Select, { ActionMeta, SingleValue } from 'react-select';

const TableFooter = ({
    range,
    setPage,
    page,
    slice,
    onClick,
}: {
    range: number[] | unknown[];
    setPage: React.Dispatch<React.SetStateAction<number>>;
    page: number;
    slice: unknown[];
    rowPerPage: number;
    onClick: (
        newValue: SingleValue<{ label: string; value: string }>,
        actionMeta: ActionMeta<{ label: string; value: string }>,
    ) => void;
}) => {
   /* const { t: generality } = useTranslation('generality');*/
    /* const RowsPerPage = [
        { label: '10', value: '10' },
        { label: '20', value: '20' },
        { label: '30', value: '30' },
        { label: '40', value: '40' },
    ]; */

    const RowsPerPage = [
        { label: '10', value: '10' },
        { label: '20', value: '20' },
        { label: '30', value: '30' },
        { label: '40', value: '40' },
        { label: '50', value: '50' },
    ];

    //Para pruebas con poca data descomentar este y comentar el de arriba
    /* const RowsPerPage = [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
    ]; */

    const goToPreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const goToNextPage = () => {
        if (page < range.length) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        if (page === 0) {
            setPage(0);
        } else if (slice.length < 1 && page !== 1) {
            setPage(page - 1);
        }
    }, [slice, page, setPage]);

    return (
        <>
            {/* <Select
                id="rows"
                name="rows"
                placeholder={''}
                className={'rounded-full'}
                styles={{
                    control(base) {
                        return {
                            ...base,
                            border: '3px solid #c3c3c3',
                            borderRadius: '9999px',
                        };
                    },
                }}
                options={RowsPerPage}
                defaultValue={RowsPerPage[0]}
                isSearchable={false}
                menuPosition={'fixed'}
                onChange={onClick}
                formatOptionLabel={(option) => (
                <div>
                    <span>{'Ver lista por '}</span>
                    <span>{option.label}</span>
                </div>
            )}
            />
            <button
                className="cursor-pointer"
                onClick={goToPreviousPage}
                disabled={page === 1}>
                <img className="h-6" src={"/serviceMaintenance/left.svg"} alt="izquierda" />
            </button>
            <span>
                {page} {'of'} {range.length}
            </span>
            <button
                className="cursor-pointer"
                onClick={goToNextPage}
                disabled={page === range.length}>
                <img className="h-6" src={"/serviceMaintenance/right.svg"} alt="derecha" />
            </button> */}

            <div className="mx-auto max-w-6xl flex w-full flex-row justify-items-start items-center ">
                <div className="w-[45%]">
                    <Select
                        id="rows"
                        name="rows"
                        placeholder={''}
                        className={'rounded-full w-[40%]'}
                        styles={{
                            control(base) {
                                return {
                                    ...base,
                                    border: '3px solid #c3c3c3',
                                    borderRadius: '9999px',
                                };
                            },
                        }}
                        options={RowsPerPage}
                        defaultValue={RowsPerPage[0]}
                        isSearchable={false}
                        menuPosition={'fixed'}
                        onChange={onClick}
                        formatOptionLabel={(option) => (
                            <div>
                                <span>{'Ver lista por '}</span>
                                <span>{option.label}</span>
                            </div>
                        )}
                    />
                </div>            
                <div className="grow flex">
                    <button
                        className="cursor-pointer"
                        onClick={goToPreviousPage}
                        disabled={page === 1}>
                        <img className="h-6" src={"/serviceMaintenance/left.svg"} alt="izquierda" />
                    </button>
                    <span className="mx-4">
                        {page} {'of'} {range.length}
                    </span>
                    <button
                        className="cursor-pointer"
                        onClick={goToNextPage}
                        disabled={page === range.length}>
                        <img className="h-6" src={"/serviceMaintenance/right.svg"} alt="derecha" />
                    </button>
                </div>
            </div>
        </>
    );
};

export default TableFooter;
