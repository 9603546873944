import React, { FormHTMLAttributes } from 'react';
import {
    FieldValues,
    UseFormHandleSubmit,
    /*UseFormReset,*/
} from 'react-hook-form';

interface IForm {
    children: React.ReactNode;
    handleSubmit: UseFormHandleSubmit<FieldValues, FieldValues>;
    onSubmit: ({ ...args }: { [x: string]: unknown }) => void;
    classNameExtra?: FormHTMLAttributes<HTMLFormElement>['className'];
    id?: string;
}

export default function BoxForm({
    children,
    handleSubmit,
    onSubmit,
    classNameExtra = '',
    id = '',
}: IForm) {
    return (
        <form
            id={id}
            onSubmit={handleSubmit(onSubmit)}
            className={`${classNameExtra !== '' ? classNameExtra : 'grid grid-cols-2 gap-4'
                } box-component-form`}>
            {children}
        </form>
    );
}